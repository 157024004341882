import * as React from 'react';
import * as styles from './project-page.module.css';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../../components/layout/layout';
import { Helmet } from "react-helmet"

const ProjectPage = ({ data }) => {
    return (
        <Layout
            title={data.mdx.frontmatter.title}
            subtitle={`a ${data.mdx.frontmatter.type} by ${data.mdx.frontmatter.author}`}
            navLeft={<Link to={`/#${data.mdx.slug.slice(0, -1)}`}>❮ Work</Link>}
        >
            <Helmet title={`Sanketh Hegde - ${data.mdx.frontmatter.title}`} />
            <div className={styles.projectContentContainer}>
                <MDXRenderer>
                    {data.mdx.body}
                </MDXRenderer>
            </div>
        </Layout>
    )
};

export const query = graphql`
    query($id: String) {
        mdx(id: {eq: $id}) {
            body
            frontmatter {
                title
                author
                type
            }
            slug
        }
    }
`;

export default ProjectPage;